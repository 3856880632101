import { markStyle } from 'modules/Campaign/components/Icons';

export const marks = {
  1: {
    style: markStyle,
    label: '1',
  },
  5: {
    style: markStyle,
    label: '5',
  },
  10: {
    style: markStyle,
    label: '10',
  },
  15: {
    style: markStyle,
    label: '15',
  },
  20: {
    style: markStyle,
    label: '20',
  },
};

export const emailMarks = {
  10: {
    style: markStyle,
    label: '10',
  },
  20: {
    style: markStyle,
    label: '20',
  },
  30: {
    style: markStyle,
    label: '30',
  },
  40: {
    style: markStyle,
    label: '40',
  },
  50: {
    style: markStyle,
    label: '50',
  },
  60: {
    style: markStyle,
    label: '60',
  },
  70: {
    style: markStyle,
    label: '70',
  },
  80: {
    style: markStyle,
    label: '80',
  },
  90: {
    style: markStyle,
    label: '90',
  },
  100: {
    style: markStyle,
    label: '100',
  },
};

export const trackStyle = { backgroundColor: '#D7A600' };
export const handleStyle = { backgroundColor: '#D7A600' };
export const railStyle = { backgroundColor: '#d1d1d1' };
