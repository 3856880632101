import { Rule } from 'antd/es/form';

import { splitContent } from './helpers';

export enum BlacklistType {
  LEAD_LINKEDIN = 'LEAD_LINKEDIN',
  LEAD_EMAIL = 'LEAD_EMAIL',
  EMAIL_DOMAIN = 'EMAIL_DOMAIN',
}

export const BLACKLIST_TYPES = [
  BlacklistType.LEAD_LINKEDIN,
  BlacklistType.LEAD_EMAIL,
  BlacklistType.EMAIL_DOMAIN,
] as const;

type BlacklistStringCheckers = {
  [key in BlacklistType]: (text: string) => boolean;
};

export const BLACKLIST_STRING_CHECKERS: BlacklistStringCheckers = {
  [BlacklistType.EMAIL_DOMAIN]: (domain) => {
    return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
      domain
    );
  },
  [BlacklistType.LEAD_EMAIL]: (email) => {
    // NOTE: this is not the most accurate email regex, but it covers most cases and just "works"
    return /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/.test(email);
  },
  [BlacklistType.LEAD_LINKEDIN]: (url) => {
    return url.trim().startsWith('https://www.linkedin.com/in/');
  },
};

type BlacklistTextAreaRules = {
  [key in BlacklistType]: Rule[];
};

export const BLACKLIST_TEXT_AREA_RULES: BlacklistTextAreaRules = {
  [BlacklistType.EMAIL_DOMAIN]: [
    {
      required: true,
      message: 'Please provide a valid domain.',
    },
    {
      validator: (_, domains: string) => {
        // if the form is empty
        if (!domains) return Promise.resolve();

        const listOfDomains = splitContent(domains);

        const invalidDomains = listOfDomains.filter(
          (domain) =>
            !BLACKLIST_STRING_CHECKERS[BlacklistType.EMAIL_DOMAIN](domain)
        );

        if (invalidDomains.length)
          return Promise.reject(
            `Please provide a valid domains list. Invalid domains: ${invalidDomains
              .slice(0, 10)
              .join(', ')}${invalidDomains.length > 10 ? '...' : ''}`
          );

        return Promise.resolve();
      },
    },
  ],
  [BlacklistType.LEAD_EMAIL]: [
    {
      required: true,
      message: 'Please provide a valid domain.',
    },
    {
      validator: (_, emails: string) => {
        if (!emails) return Promise.resolve();

        const listOfEmails = splitContent(emails);

        const invalidEmails = listOfEmails.filter(
          (email) => !BLACKLIST_STRING_CHECKERS[BlacklistType.LEAD_EMAIL](email)
        );

        if (invalidEmails.length)
          return Promise.reject(
            `Please provide a valid emails list. Invalid emails: ${invalidEmails
              .slice(0, 10)
              .join(', ')}${invalidEmails.length > 10 ? '...' : ''}`
          );

        return Promise.resolve();
      },
    },
  ],
  [BlacklistType.LEAD_LINKEDIN]: [
    {
      required: true,
      message: 'Please provide a LinkedIn profile URL',
    },
    {
      validator: (_, urls: string) => {
        if (!urls) return Promise.resolve();

        const listOfUrls = splitContent(urls);

        const invalidUrls = listOfUrls.filter(
          (url) => !BLACKLIST_STRING_CHECKERS[BlacklistType.LEAD_LINKEDIN](url)
        );

        if (invalidUrls.length)
          return Promise.reject(
            `Please provide a valid list of LinkedIn profile URLs. Invalid URLs: ${invalidUrls
              .slice(0, 10)
              .join(', ')}${invalidUrls.length > 10 ? '...' : ''}`
          );

        return Promise.resolve();
      },
    },
  ],
};

type BlacklistTextAreaPlaceholders = {
  [key in BlacklistType]: string;
};

export const BLACKLIST_TEXT_AREA_PLACEHOLDERS: BlacklistTextAreaPlaceholders = {
  [BlacklistType.EMAIL_DOMAIN]: 'e.g: email.com, company.com...',
  [BlacklistType.LEAD_EMAIL]: 'e.g: lead@email.com, example@company.com...',
  [BlacklistType.LEAD_LINKEDIN]:
    'e.g: https://www.linkedin.com/in/lead, https://www.linkedin.com/in/example...',
};

type BlacklistTitles = {
  [key in BlacklistType]: string;
};

export const BLACKLIST_TITLES: BlacklistTitles = {
  [BlacklistType.EMAIL_DOMAIN]: 'Domain',
  [BlacklistType.LEAD_EMAIL]: 'Email',
  [BlacklistType.LEAD_LINKEDIN]: 'LinkedIn Profile Link',
};

type BlacklistDescriptions = {
  [key in BlacklistType]: string;
};

export const BLACKLIST_DESCRIPTIONS: BlacklistDescriptions = {
  [BlacklistType.EMAIL_DOMAIN]:
    'Email domains that will be excluded from the leads of email campaigns',
  [BlacklistType.LEAD_EMAIL]:
    'Email addresses that will be excluded from email campaigns',
  [BlacklistType.LEAD_LINKEDIN]:
    "Lead's LinkedIn profile URLs that will be excluded from LinkedIn outreach campaigns",
};
