import { InboxOutlined } from '@ant-design/icons';
import { Form, Input, Slider } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import { CREATE_CAMPAIGN, EDIT_CAMPAIGN } from 'constant/campaignForm';
import {
  emailMarks,
  handleStyle,
  railStyle,
  trackStyle,
} from 'constant/formComponents';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import { InfoEmailSignature } from 'modules/Campaign/components/Form/InfoEmailSignature';
import { MessageFormItem } from 'modules/Campaign/components/Form/MessageFormItem';
import TooltipCSVDownload from 'modules/Campaign/components/Form/TooltipCSVDownload';
import { useState } from 'react';
import {
  isEmailCSV,
  isEmailTargetMargetCampaignType,
} from 'services/campaignType';
import { parseAndValidateLeadsFromCsvForEmail } from 'utils/helpers/csv';

export const BasicEmailInfo = () => {
  const [fileList, setFileList] = useState([]);

  const form = Form.useFormInstance();

  return (
    <div className="grid gap-6 fade-in md:grid-cols-2">
      <FormSection
        title={EDIT_CAMPAIGN.name.title}
        subTitle={EDIT_CAMPAIGN.name.label}
      >
        <Form.Item
          name="name"
          rules={[
            { required: true, message: 'Please give this campaign a name' },
          ]}
          className="mb-0"
        >
          <Input />
        </Form.Item>
      </FormSection>

      {isEmailCSV(form.getFieldValue('type')) && (
        <FormSection
          title={CREATE_CAMPAIGN.csv.title}
          titleChildren={<TooltipCSVDownload />}
        >
          <Form.Item
            name="file"
            rules={[
              {
                validator: async (_, val) => {
                  if (!val) return;
                  const { file } = val;
                  setFileList([]);
                  try {
                    const parsedLeads =
                      await parseAndValidateLeadsFromCsvForEmail(file);
                    form.setFieldsValue({ parsedLeads });
                    // Validation passed, set file to state and value to form
                    setFileList([file]);
                    // Extra function for the edit form
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject(error);
                  }
                },
              },
            ]}
            className="mb-0"
          >
            <Dragger
              accept=".csv"
              maxCount={1}
              beforeUpload={() => false}
              fileList={fileList}
              showUploadList={{ showRemoveIcon: false }}
            >
              <div className="flex justify-center text-gray-500 align-middle">
                <InboxOutlined className="mx-2 text-2xl text-yellow-400" />
                Click or drag to upload a file
              </div>
            </Dragger>
          </Form.Item>
          {/* This extra form item is used to avoid prop drilling */}
          <Form.Item name="parsedLeads" hidden>
            <Input />
          </Form.Item>
        </FormSection>
      )}

      {isEmailTargetMargetCampaignType(form.getFieldValue('type')) && (
        <FormSection
          title={CREATE_CAMPAIGN.targetMarket.title}
          subTitle={CREATE_CAMPAIGN.targetMarket.subTitle}
        >
          <Form.Item
            name="targetMarketName"
            rules={[
              { required: true, message: 'Please give this campaign a name' },
            ]}
            className="mb-0"
          >
            <Input disabled />
          </Form.Item>
        </FormSection>
      )}

      <FormSection
        title={EDIT_CAMPAIGN.emailRange.title}
        subTitle={EDIT_CAMPAIGN.emailRange.subTitle}
        className="md:col-span-2"
      >
        <Form.Item name="invites" noStyle>
          <Slider
            range={true}
            step={1}
            getAriaLabel={() => 'Emails per day'}
            min={10}
            max={100}
            trackStyle={trackStyle}
            handleStyle={handleStyle}
            railStyle={railStyle}
            valueLabelDisplay="auto"
            getAriaValueText={(value) => `${value} invites`}
            disableSwap
            marks={emailMarks}
            className="mt-4"
          />
        </Form.Item>
      </FormSection>

      <FormSection title={EDIT_CAMPAIGN.email.title} className="md:col-span-2">
        <div className="-mb-4">
          <FormLabel>{EDIT_CAMPAIGN.subject.label}</FormLabel>
          <Form.Item
            name="emailFollowUpsSubject"
            rules={[
              {
                required: true,
                message: 'Please enter the subject of the email',
              },
            ]}
          >
            <Input placeholder="Email Subject" />
          </Form.Item>
        </div>
        <MessageFormItem
          name="initialMessage"
          title="Initial contact message"
          className="pt-6"
        />
      </FormSection>

      <FormSection
        title={EDIT_CAMPAIGN.signature.title}
        subTitle={EDIT_CAMPAIGN.signature.label}
        className="mb-4 md:col-span-2"
      >
        <div className="flex flex-col gap-4 sm:flex-row">
          <Form.Item name="emailSignature" noStyle>
            <TextArea
              className="flex-1 p-2 px-4"
              placeholder={`Ex.: John Doe\n\nRole | Company\nwww.mycompany.com\n+49 123 456789\n\nKreuzstr. 123\nBerlin`}
              autoSize={{ minRows: 6.5, maxRows: 10 }}
              onChange={(e) => {
                form.setFieldValue('emailSignature', e.target.value);
              }}
            />
          </Form.Item>
          <InfoEmailSignature className="flex-1" />
        </div>
      </FormSection>
    </div>
  );
};
