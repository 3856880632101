import { InboxOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Slider } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import { CREATE_CAMPAIGN } from 'constant/campaignForm';
import {
  emailMarks,
  handleStyle,
  railStyle,
  trackStyle,
} from 'constant/formComponents';
import { EmailSignatureForm } from 'modules/Campaign/components/Form/EmailSignatureForm';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import { InfoEmailSignature } from 'modules/Campaign/components/Form/InfoEmailSignature';
import { MessageFormItem } from 'modules/Campaign/components/Form/MessageFormItem';
import TooltipCSVDownload from 'modules/Campaign/components/Form/TooltipCSVDownload';
import { useState } from 'react';
import { renderSignaturePreview } from 'services/campaignForm';
import { isEmailCSV } from 'services/campaignType';
import { parseAndValidateLeadsFromCsvForEmail } from 'utils/helpers/csv';

export const EmailCampaignSetup = () => {
  const form = Form.useFormInstance();
  const existingfileList = form.getFieldValue('file')?.fileList;

  const [fileList, setFileList] = useState(
    existingfileList ? existingfileList : []
  );
  const [_, setSignature] = useState('');
  const [templatedSignature, setTemplatedSignature] = useState('');

  const [showSignatureDrawer, setShowSignatureDrawer] = useState(false);

  const showDrawer = () => {
    setShowSignatureDrawer(true);
  };

  const onCreate = () => {
    setSignature(renderSignaturePreview(templatedSignature));
    form.setFieldValue(
      'emailSignature',
      renderSignaturePreview(templatedSignature)
    );
    setShowSignatureDrawer(false);
  };

  return (
    <div className="grid fade-in">
      {isEmailCSV(form.getFieldValue('type')) && (
        <FormSection
          title={CREATE_CAMPAIGN.csv.title}
          subTitle={CREATE_CAMPAIGN.csv.subTitle}
          titleChildren={<TooltipCSVDownload />}
        >
          <Form.Item
            name="file"
            rules={[
              {
                required: true,
                message: 'CSV file is required',
              },
              {
                validator: async (_, val) => {
                  if (!val) return;
                  const { file } = val;
                  setFileList([]);
                  try {
                    const parsedLeads =
                      await parseAndValidateLeadsFromCsvForEmail(file);
                    form.setFieldsValue({ parsedLeads });
                    // Validation passed, set file to state and value to form
                    setFileList([file]);
                    // Extra function for the edit form
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject(error);
                  }
                },
              },
            ]}
          >
            <Dragger
              accept=".csv"
              maxCount={1}
              beforeUpload={() => false}
              fileList={fileList}
              showUploadList={{ showRemoveIcon: false }}
            >
              <div className="flex justify-center text-gray-500 align-middle">
                <InboxOutlined className="mx-2 text-2xl text-yellow-400" />
                Click or drag to upload a file
              </div>
            </Dragger>
          </Form.Item>
          {/* This extra form item is used to avoid prop drilling */}
          <Form.Item name="parsedLeads" initialValue={[]} hidden>
            <Input />
          </Form.Item>
        </FormSection>
      )}

      <FormSection
        title={CREATE_CAMPAIGN.range.title}
        subTitle={CREATE_CAMPAIGN.range.subTitle}
      >
        <Form.Item name="invites" initialValue={[15, 25]}>
          <Slider
            range={true}
            step={1}
            getAriaLabel={() => 'Emails per day'}
            min={10}
            max={100}
            trackStyle={trackStyle}
            handleStyle={handleStyle}
            railStyle={railStyle}
            valueLabelDisplay="auto"
            getAriaValueText={(value) => `${value} invites`}
            minimumTrackTintColor="blue"
            maximumTrackTintColor="red"
            disableSwap
            marks={emailMarks}
          />
        </Form.Item>
      </FormSection>

      <FormSection
        title={CREATE_CAMPAIGN.email.title}
        subTitle={CREATE_CAMPAIGN.email.subTitle}
      >
        <div>
          <FormLabel>Email Subject</FormLabel>
          <Form.Item
            name="emailFollowUpsSubject"
            rules={[
              {
                required: true,
                message: 'Please enter the subject of the email',
              },
            ]}
          >
            <Input placeholder="Email Subject" />
          </Form.Item>
        </div>
        <MessageFormItem
          name="initialMessage"
          title="Initial contact message"
        />
      </FormSection>

      <FormSection
        title={CREATE_CAMPAIGN.signature.title}
        subTitle={CREATE_CAMPAIGN.signature.subTitle}
        className="mb-4"
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="self-end text-gray-500">
            Paste your signature here
          </div>
          <Button
            type="link"
            onClick={showDrawer}
            className="h-auto px-4 py-2 -mb-2 -mr-4 text-xs text-secondary justify-self-end"
          >
            I don&apos;t have a signature
          </Button>
          <Form.Item name="emailSignature" noStyle>
            <TextArea
              className="col-span-2 p-2 px-4 md:col-span-1"
              placeholder={`Ex.: John Doe\n\nRole | Company\nwww.mycompany.com\n+49 123 456789\n\nKreuzstr. 123\nBerlin`}
              autoSize={{ minRows: 6.5, maxRows: 10 }}
              onChange={(e) => {
                form.setFieldValue('emailSignature', e.target.value);
              }}
            />
          </Form.Item>
          <InfoEmailSignature className="col-span-2 md:col-span-1" />
        </div>
      </FormSection>

      {/* Drawer to create custom signature */}
      <Drawer
        destroyOnClose
        title="Create a new signature"
        className="max-w-[100vw] !w-[42rem]"
        width=""
        onClose={() => setShowSignatureDrawer(false)}
        open={showSignatureDrawer}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <>
            <Button
              onClick={() => setShowSignatureDrawer(false)}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button onClick={onCreate} type="primary">
              Submit
            </Button>
          </>
        }
      >
        <Form.Item name="emailSignature" initialValue={''} hidden>
          <Input />
        </Form.Item>
        <EmailSignatureForm
          templatedSignature={templatedSignature}
          setTemplatedSignature={setTemplatedSignature}
        />
      </Drawer>
    </div>
  );
};
